<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div>
    <div class="admin-property-list-item-container-desktop d-md-flex align-items-center">
      <div class="property align-items-center d-md-flex w-100">
        <div class="d-flex align-items-center mobile-border-margin">
          <div class="property-image me-2"
               v-bind:style="{'background-image': (image ? 'url(/storage/' + image + ')' : 'none')}"></div>
          <div class="col-9 d-md-none">
            <div class="d-flex">
              <div class="ms-md-2 m property-title">{{ title }}</div>
              <div class="item-icon ms-auto d-flex d-md-none ">
                <router-link :to="{name: 'SinglePropertyPage', params: {id: propertyid}}" target="_blank">
                  <svg width="23" height="14" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1349_585)">
                      <path
                          d="M0 4.8769C0.376006 4.42877 0.729516 3.95916 1.13766 3.53866C2.06643 2.58101 3.1141 1.74922 4.30318 1.09544C5.27373 0.56137 6.30212 0.17463 7.4205 0.0457167C8.67385 -0.0985436 9.87578 0.104035 11.0327 0.576717C12.5175 1.18445 13.7869 2.08992 14.9149 3.17954C15.3841 3.63074 15.8115 4.12491 16.2454 4.6068C16.4928 4.87997 16.4832 5.11017 16.2486 5.39256C15.0884 6.79526 13.7419 7.99845 12.119 8.90084C11.1452 9.44105 10.1104 9.82472 8.98879 9.95364C7.71616 10.0979 6.50137 9.88304 5.33479 9.39808C3.87575 8.79035 2.6224 7.89409 1.51045 6.81981C1.04446 6.37168 0.623463 5.88059 0.18961 5.40483C0.11248 5.32196 0.0642745 5.2176 0.00321373 5.12245C0 5.03958 0 4.95977 0 4.8769ZM1.23086 4.9874C1.27906 5.04572 1.30799 5.08562 1.34655 5.12245C1.84789 5.61662 2.32995 6.13227 2.86022 6.59882C3.86611 7.47973 4.9877 8.20103 6.28926 8.63381C7.15054 8.92233 8.0311 9.03589 8.93737 8.91312C9.9497 8.77807 10.8753 8.40667 11.7462 7.90637C12.9095 7.23418 13.9058 6.37782 14.8121 5.41711C14.9406 5.28206 15.0595 5.14087 15.1945 4.99047C14.8924 4.67739 14.6064 4.36739 14.3043 4.07273C13.2373 3.03528 12.0579 2.14209 10.6503 1.55892C9.96577 1.27346 9.25232 1.08623 8.50352 1.0494C7.33051 0.991082 6.24748 1.30109 5.21588 1.8106C4.07179 2.37537 3.08196 3.13964 2.17891 4.01134C1.85432 4.32442 1.54902 4.65898 1.23086 4.9874Z"
                          fill="#434D56"/>
                      <path
                          d="M8.20787 1.8965C9.99792 1.89037 11.4602 3.28386 11.4602 4.99657C11.4602 6.70927 9.99792 8.10584 8.20787 8.0997C6.41461 8.09356 4.97807 6.72462 4.96522 5.00577C4.94915 3.30228 6.40818 1.90571 8.20787 1.8965ZM8.20466 7.05918C9.40016 7.06532 10.3643 6.15065 10.3707 5.00577C10.3771 3.8609 9.41944 2.94316 8.22072 2.93702C7.02522 2.93088 6.0611 3.84555 6.05467 4.99043C6.04825 6.13223 7.00272 7.05304 8.20466 7.05918Z"
                          fill="#434D56"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1349_585">
                        <rect width="16.4286" height="10" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                </router-link>
              </div>
            </div>

            <div class="d-flex">
              <div class="item-date col-6">{{ dateFormatted }}</div>
              <div class="item-time col-6">{{ timeFormatted }}</div>
            </div>
          </div>
        </div>

        <div class="ms-md-2 m property-title d-none d-md-block">{{ title }}</div>
        <div class="item-name ms-auto col-md-3">{{ name }}</div>
        <div class="item-contact d-flex d-md-block col-12 col-md-4  ">
          <div>{{ phone }}</div>
          <div class="ms-auto ms-md-0">{{ email }}</div>
        </div>
      </div>
      <div class="item-date col-md-2 d-none d-md-block ">{{ dateFormatted }}</div>
      <div class="item-time col-md-1 d-none d-md-block">{{ timeFormatted }}</div>
      <div class="item-icon  d-md-flex d-none ">
        <router-link :to="{name: 'SinglePropertyPage', params: {id: propertyid}}" target="_blank">
          <svg width="23" height="14" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1349_585)">
              <path
                  d="M0 4.8769C0.376006 4.42877 0.729516 3.95916 1.13766 3.53866C2.06643 2.58101 3.1141 1.74922 4.30318 1.09544C5.27373 0.56137 6.30212 0.17463 7.4205 0.0457167C8.67385 -0.0985436 9.87578 0.104035 11.0327 0.576717C12.5175 1.18445 13.7869 2.08992 14.9149 3.17954C15.3841 3.63074 15.8115 4.12491 16.2454 4.6068C16.4928 4.87997 16.4832 5.11017 16.2486 5.39256C15.0884 6.79526 13.7419 7.99845 12.119 8.90084C11.1452 9.44105 10.1104 9.82472 8.98879 9.95364C7.71616 10.0979 6.50137 9.88304 5.33479 9.39808C3.87575 8.79035 2.6224 7.89409 1.51045 6.81981C1.04446 6.37168 0.623463 5.88059 0.18961 5.40483C0.11248 5.32196 0.0642745 5.2176 0.00321373 5.12245C0 5.03958 0 4.95977 0 4.8769ZM1.23086 4.9874C1.27906 5.04572 1.30799 5.08562 1.34655 5.12245C1.84789 5.61662 2.32995 6.13227 2.86022 6.59882C3.86611 7.47973 4.9877 8.20103 6.28926 8.63381C7.15054 8.92233 8.0311 9.03589 8.93737 8.91312C9.9497 8.77807 10.8753 8.40667 11.7462 7.90637C12.9095 7.23418 13.9058 6.37782 14.8121 5.41711C14.9406 5.28206 15.0595 5.14087 15.1945 4.99047C14.8924 4.67739 14.6064 4.36739 14.3043 4.07273C13.2373 3.03528 12.0579 2.14209 10.6503 1.55892C9.96577 1.27346 9.25232 1.08623 8.50352 1.0494C7.33051 0.991082 6.24748 1.30109 5.21588 1.8106C4.07179 2.37537 3.08196 3.13964 2.17891 4.01134C1.85432 4.32442 1.54902 4.65898 1.23086 4.9874Z"
                  fill="#434D56"/>
              <path
                  d="M8.20787 1.8965C9.99792 1.89037 11.4602 3.28386 11.4602 4.99657C11.4602 6.70927 9.99792 8.10584 8.20787 8.0997C6.41461 8.09356 4.97807 6.72462 4.96522 5.00577C4.94915 3.30228 6.40818 1.90571 8.20787 1.8965ZM8.20466 7.05918C9.40016 7.06532 10.3643 6.15065 10.3707 5.00577C10.3771 3.8609 9.41944 2.94316 8.22072 2.93702C7.02522 2.93088 6.0611 3.84555 6.05467 4.99043C6.04825 6.13223 7.00272 7.05304 8.20466 7.05918Z"
                  fill="#434D56"/>
            </g>
            <defs>
              <clipPath id="clip0_1349_585">
                <rect width="16.4286" height="10" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </router-link>
      </div>
      <div class=" item d-flex">


      </div>
    </div>
  </div>
</template>
