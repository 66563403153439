import PagePreloader from "@/components/Components/PagePreloader/PagePreloader.vue";

;

import Button from '@/components/Common/Buttons/Button/Button';
import InputField from '@/components/Common/Inputs/Input/Input';
import FileInput from '@/components/Common/Inputs/FileInput/FileInput';
import TextArea from '@/components/Common/Inputs/TextArea/TextArea';
import Dropdown from '@/components/Common/Inputs/Dropdown/Dropdown';
import CheckBox from '@/components/Common/Inputs/CheckBox/CheckBox';
import YesNoInput from '@/components/Common/Inputs/YesNoInput/YesNoInput';
import MakePremium from '@/components/Components/MakePremium/MakePremium/';
import PremiumAdSelection from '@/components/Components/PremiumAdSelection/PremiumAdSelection/';
import ViewingInquiry from '@/components/Components/ViewingInquiry/ViewingInquiry/';
import CreditWallet from '@/components/Components/CreditWallet/CreditWallet/';
import AdUpdate from '@/components/Components/AdUpdate/AdUpdate/';
import Pagination from '@/components/Components/Pagination/Pagination/';
import UserTypeMobile from '@/components/Header/HeaderMobile/UserTypeMobile/UserTypeMobile';
import UserNavMobile from '@/components/Header/HeaderMobile/UserNavMobile/UserNavMobile';


export default {
    name: 'AdminViewingRequests',
    components: {
        PagePreloader,
        Button,
        CheckBox,
        MakePremium,
        AdUpdate,
        PremiumAdSelection,
        CreditWallet,
        ViewingInquiry,
        TextArea,
        Dropdown,
        InputField,
        UserTypeMobile,
        UserNavMobile,
        YesNoInput,
        FileInput,
        Pagination,
    },
    data() {
        return {
            inquiries: null,
            totalInquiries: 0,
            limit: 5,
            offset: 0,
            currentPage: 1,
            isLoading: false
        };
    },
    async mounted() {
        if (this.$store.state.user.info.type === 'vendor') {
            this.$router.push({name: 'VendorDashboard'});
            return;
        }

        await this.loadInquiries();
    },
    methods: {
        async handlePageChange(page) {
            this.currentPage = page;
            this.offset = (this.currentPage - 1) * this.limit;
            await this.loadInquiries();
        },
        async loadInquiries() {
            this.isLoading = true;
            this.inquiries = null;
            try {
                const inquiries = await this.$store.dispatch('propertyView/getInquiries', {
                    offset: this.offset,
                    limit: this.limit
                });
                this.inquiries = inquiries.data;
                this.totalInquiries = inquiries.total;
            } catch (err) {
                console.log(err);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
