import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import dateInput from "@/components/Common/Inputs/DateInput";
import DateTime from "@/utils/date";

export default {
    name: 'ViewingInquiry',
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew

    },
    props: {
        name: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        time: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        propertyid: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            dateFormatted: null,
            timeFormatted: null,
        }
    },
    mounted() {
        const date = new Date(this.$props.date);
        this.dateFormatted = DateTime.formatDate(this.$props.date);
        this.timeFormatted = DateTime.formatTime(this.$props.date);
    }
}