export default class DateTime {
	static formatDate(dateString) {
		const date = new Date(dateString);
		return date.getFullYear() + '-' + this.pad(date.getMonth() + 1) + '-' + this.pad(date.getDate());
	}

	static formatTime(dateString) {
		const date = new Date(dateString);
		return this.pad(date.getHours()) + ':' + this.pad(date.getMinutes());
	}

	static pad(string) {
		return String(string).padStart(2, '0');
	}
}
